@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Montserrat", serif !important;
  margin: 0px;
  text-transform: none;
}

html {
  --ion-safe-area-top: env(safe-area-inset-top);
  --ion-safe-area-bottom: env(safe-area-inset-bottom);
  --ion-safe-area-left: env(safe-area-inset-left);
  --ion-safe-area-right: env(safe-area-inset-right);
}


#root {
  width: 100vw;
}

.notch {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top) !important;
}

.bottomNotch {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom) !important;
}

.homeContentContainer {
  padding-top: calc(env(safe-area-inset-top)) !important;
}

.css-1ujq6ap-MuiContainer-root {
  padding: 0 !important;
  margin-top: 8px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.d-hotline {
  display: none !important;
}

.copy-icon {
  opacity: 0.3;
  cursor: pointer;
}

.copy-icon:hover {
  opacity: 1;
}